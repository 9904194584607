jQuery(function($) {
	
	if($('#heading').length) {
		$('#heading').slick({
			arrows: false,
			autoplay: true,
			fade: true
		});
	}
	
	$('#header #search a').click(function() {
		$(this).parent().toggleClass('open');
	});
	
	$('#search a.advanced').click(function(e) {
		e.preventDefault();
		$(this).parent().parent().find('.hidden').stop().slideToggle();
	});
	
	$('#members-button').click(function() {
		$('#members-button, .members-menu').toggleClass('open');
	});
	
	$('#header #navigation button').click(function() {
		$(this).toggleClass('open');
		$('#header #top').toggleClass('white');
		$(this).parent().find('.menu').stop().slideToggle();
		$(this).parent().find('ul ul.open').slideUp().removeClass('open');
	});
	
	$('#header #navigation li.menu-item-has-children > a').click(function(e) {
		if($(window).width() < 992) {
			if(!$(this).parent().find('ul').hasClass('open')) {
				e.preventDefault();
				$(this).parent().parent().find('ul.open').slideUp().removeClass('open');
				$(this).parent().find('ul').slideDown().addClass('open');
			}
		}
	});
	
	$(window).resize(function() {
		if($(window).width() >= 992) {
			$('#header #navigation ul ul.open').removeClass('open').removeAttr('style');
		}
	});
	
	if($('#membership-page .membership-type-field select').length) {
		getMembershipPrice();	
	}
	
	$(document).on('change', '#membership-page .membership-type-field select', function() {
		getMembershipPrice();
	});
	
	$('.content-block-faqs strong').click(function() {
		$(this).parent().stop().toggleClass('open');
	});
	
	$('#membership-page #options tbody tr td:first-of-type').click(function() {
		if($(window).width() < 992) {
			$(this).parent().toggleClass('open');
		}
	});

	$('#archive-lecture .details-toggle').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('open').parent().find('.details').stop().slideToggle(200);

		if($(this).hasClass('open')) {
			$(this).html('Hide details');
		} else {
			$(this).html('More details');
		}
	});

	$('#archive-speaker .details-toggle').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('open').parent().find('.details').stop().slideToggle(200);

		if($(this).hasClass('open')) {
			$(this).html('Hide lectures');
		} else {
			$(this).html('View lectures');
		}
	});
});

function getMembershipPrice() {
	var name = jQuery('#membership-page .membership-type-field select').val();
	var options = wpvars.membership_options;
	
	jQuery('#membership-page form .price span').html('');
	
	for(var i = 0; i < options.length; i++) {

		if(options[i].name == name) {
			jQuery('#membership-page form .price span').html('&pound;' + options[i].price + ' per year');
			jQuery('#membership-page form .membership-price-field input').val(options[i].price);
		}
	}
}